<template>
  <CheckListWrapper>
    <sdCards :title="$t('views.userProfile.permissions')"
             :caption="`(${$t('views.userProfile.permissionsCaption')})`">
      <a-row :gutter="15">
        <a-col :xs="24">
          <div class="checklist-box-single">
              <div class="checklist-body">
                <nav>
                  <ul>
                    <li v-for="permission in permissions" :key="permission.id" :style="listStyle">
                      <div class="checklist-single-item">
                        <sdHeading class="checklist-single-item__title" as="h4">
                          {{permission.displayName}}
                        </sdHeading>
                      </div>
                      <a-switch :checked="permissionChecked[permission.id]"
                                :disabled="!$can('updatePermissions', user)"
                                @change="updatePermissions(permission.id, $event)"/>
                    </li>
                  </ul>
                </nav>
              </div>
          </div>
        </a-col>
      </a-row>
    </sdCards>
  </CheckListWrapper>
</template>

<script>
import {
  computed, defineComponent, reactive, watch
} from 'vue';
import {useStore} from 'vuex';
import _ from 'lodash';
import {useRoute} from 'vue-router';
import {CheckListWrapper} from '@/components/users/style';

export default defineComponent({
  components: {CheckListWrapper},
  setup() {
    const listStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      padding: 0
    };
    const {
      dispatch, getters, state
    } = useStore();
    dispatch('getPermissions');
    const user = computed(() => getters.user);
    const userPermissions = computed(() => getters.user.permissionIds);
    const permissions = computed(() => getters.permissions);

    const permissionChecked = reactive({});
    watch(userPermissions, (permissions) => {
      if (_.isEmpty(permissionChecked)) {
        _.forEach(permissions, (permission) => {
          permissionChecked[permission] = _.includes(userPermissions.value, permission);
        });
      }
    }, {deep: true, immediate: true});

    const updatePermissions = async (permissionId, value) => {
      permissionChecked[permissionId] = value;
      const form = {};
      form[_.snakeCase('permissionIds')] = _.keys(_.pickBy(permissionChecked, (value) => value));
      const status = getters.user._jv.id === state.session.currentUser._jv.id ? await dispatch('updateAccount', form) :
        await dispatch('updateUser', {form, id: getters.user._jv.id});

      if (status !== 200) {
        permissionChecked[permissionId] = _.includes(userPermissions.value, permissionId);
      }
    };

    const route = useRoute();
    watch(() => route.params, () => {
      if (route.name.toString().includes('UserProfile')) {
        Object.assign(permissionChecked, {});
      }
    }, {immediate: true, deep: true});

    return {
      permissions,
      listStyle,
      userPermissions,
      user,
      permissionChecked,
      updatePermissions
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-switch-disabled {
    opacity: 1 !important;
}
</style>
